<template>
  <section>
    <section>
      <p class="order-title">订单信息</p>

      <a-row :gutter="20" class="m-t-16">
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">采购订单编号:</span>
          <span style="color: #93bbf3">{{ infoDetail.orderCode }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">批次订单编号:</span>
          <span style="color: #93bbf3">{{ infoDetail.batchCode }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">入库时间:</span>
          <span style="color: #93bbf3">{{
            infoDetail.inTime ? infoDetail.inTime.split(" ")[0] : ""
          }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">经办人:</span>
          <span style="color: #93bbf3">{{ infoDetail.operator }}</span>
        </a-col>
      </a-row>
      <a-row :gutter="20" class="m-t-16">
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">送货人:</span>
          <span style="color: #93bbf3">{{ infoDetail.deliveryman }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">入库单据编号:</span>
          <span style="color: #93bbf3">{{ infoDetail.code }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">供货商:</span>
          <span style="color: #93bbf3">{{ infoDetail.supplier }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">存放仓库:</span>
          <span style="color: #93bbf3">{{ infoDetail.warehouseName }}</span>
        </a-col>
      </a-row>
    </section>
    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="dataSource"
        :pagination="false"
        size="small"
        class="m-t-18"
        :columns="columns"
      >
      </a-table>
    </section>

    <section>
      <a-row :gutter="20">
        <a-col :span="24">
          <a-form-item label="入库照片">
            <a-row class="upload-preview-wrap">
              <!-- <a-avatar shape="square"  v-for="(item,index) in  imagesList" :key="index"
                style="width: 100px; height: 100px;margin-right:10px"
                :src="item"
              /> -->
             
              <a class="file-item m-r-10" v-for="(url,ind) in imagesList" :key="ind">
                  <preview-image :data="url" :size="100" />
              </a>
              <!-- <a-avatar
                style="width: 100px; height: 100px"
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              /> -->
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="1"> 备注 </a-col>
        <a-col :span="20">
          <a-row>
            {{ remarksList }}
          </a-row>
          <!-- <a-row>
             备注
          </a-row> -->
        </a-col>
      </a-row>
    </section>
    <section class="form-container">
      <a-row class="form-btn-group">
        <a-button class="btn-save" @click="$router.go(-1)">返回</a-button>
      </a-row>
    </section>
  </section>
</template>
<script>
import previewImage from "@/components/previewImage";
export default {
  data() {
    return {
      id: "",
      infoDetail: {},
      remarksList: "",
      imagesList: [],
      columns: [
        { title: "序号", dataIndex: "key", align: "center", width: 200 },
        {
          title: "材料名称",
          dataIndex: "materialName",
          align: "center",
          width: 200,
        },
        { title: "品牌", dataIndex: "brand", align: "center", width: 200 },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
        },
        {
          title: "送货数量",
          dataIndex: "batchQty",
          align: "center",
          width: 200,
        },
        {
          title: "入库数量",
          dataIndex: "receivedQty",
          align: "center",
          width: 200,
        },
      ],

      dataSource: [
        {
          key: "0",
          ind: "0",
          name: "螺纹钢筋螺纹钢筋",
          ind1: "桂万钢",
          ind2: "Φ25",
          ind3: "吨",
          ind4: "23",
          ind5: "桂万钢",
          ind6: "Φ25",
          ind7: "吨",
          ind8: "23",
          ind9: "23",
        },
      ],
      orderdataSource: [],
    };
  },
  created() {
    this.inStorageDetail();
  },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    },
  },
  components: { previewImage },
  methods: {
    inStorageDetail() {
      this.id = this.$route.query.id;
      this.$api.inStorageDetail(this.id).then((res) => {
        if (res.code == "200") {
          this.infoDetail = res.data;
          this.imagesList= res.data.images ? res.data.images.split(",") : [];
          // 兼容图片
          // list.forEach((item) => {
          //   if (item.indexOf("https") == -1) {
          //     this.imagesList.push(this.picServer + item);
          //   }
          // });
          
          // console.log(this.imagesList);
          this.remarksList = res.data.remarks;
          if (res.data.detailList && res.data.detailList.length > 0) {
            res.data.detailList.forEach((item, index) => {
              item.key = index + 1;
            });
            this.dataSource = res.data.detailList;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #1c3d6c;
  color: #93bbf3;
}
/deep/.ant-collapse-content-box {
  border: 1px solid rgba(31, 54, 144, 0.7);
}
/deep/.ant-collapse {
  background: #1c3d6c !important;
}
/deep/.ant-collapse-item {
  margin-bottom: 0 !important;
  background: rgba(20, 34, 89, 0.7) !important;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
.ordertotalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 140px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 215px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}

.order-info {
  margin: 18px 12px 0 12px;
  padding: 0 10px;
}
.order-title {
  line-height: 40px;
  padding-bottom: 4px;
  border-bottom: 1px solid #2f538f;
  font-size: 15px;
  color: #529aff;
  margin-bottom: 8px;
  font-weight: bold;
}
.textarea,
.form-container {
  margin-top: 40px;
}
</style>